@import "./themes/kendo-theme-custom.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/** GREASEBOSS TEXT CLASSES **/
.gb-h1 {
  @apply text-3xl;
  color: #323543;
}

.gb-h2 {
  @apply text-2xl;
  color: #323543;
}

.gb-h3 {
  @apply text-gray-600 text-xl;
  color: #323543 !important;
}

.gb-text-normal {
  @apply text-gray-700;
}

.sidebar .k-widget.k-drawer.k-drawer-start {
  background: #323543 !important;
  // height: calc(100vh - 136px) !important;
  color: white;
}

.k-drawer {
  background: #323543 !important;
  color: white;
}

.currentTotal .k-card-header {
  border-color: transparent;
  padding: 10px 0 0 10px;
  background: #5e5e5e;
  color: white;
  display: none;
}

.currentTotal .k-card-body .red {
  border-color: transparent;
  background: #ff5034;
  color: white;
  height: 100%;
}

.currentTotal .k-card-body .green {
  border-color: transparent;
  background: #1ab394;
  color: white;
  height: 100%;
}

.currentTotal .k-card-body {
  padding: 0;
  background: #5e5e5e;
  color: white;
}

.k-tilelayout.k-grid-flow-col {
  background: transparent;
}

.k-drawer-container {
  .k-drawer {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-right-width: 0 !important;
    color: #ffffff;
  }

  &.k-drawer-push .k-drawer {
    position: sticky;
    top: 68px;
    height: calc(100vh - 104px);
  }

  .k-drawer-item {
    user-select: none;
  }
}

#legend-point-mapping div {
  display: flex !important;
  background-color: transparent !important;
}

.k-widget.k-drawer.k-drawer-start {
  height: auto !important;
}

// .main-treelist thead tr.k-table-row:first-child {
//   display: none !important;
// }

.main-treelist tbody {
  cursor: pointer !important;
}

.main-treelist .k-grid tr:hover {
  background: #ededed !important;
}

.endpoint-status-all .k-tilelayout-item-body.k-card-body {
  padding-top: 10px !important;
  padding-bottom: 0 !important;
}

//ensure that all elements withing the kendo tabstrip can expand to 100% width
.k-tabstrip>.k-content>.k-animation-container,
.k-animation-container.k-animation-container-relative {
  width: 100% !important;
}

// Added to change the border colour from required dialog action bar
.k-multiselecttree.k-input.k-input-md.k-rounded-md.k-input-solid.k-invalid.k-required{
  border-color: #ededed;
}

//ensure that multiselct treeview is scrollable
.k-child-animation-container ul.k-treeview-group {
  max-height: 300px !important;
  overflow: auto !important;
}

//changing tile layout from dashboard page
.health-tab-tile div.k-tilelayout-item-header.k-card-header {
  display: none !important;
}
